<template>
    <div class="g-section job-container">
        <div class="g-wrap job-top">
            <div class="flex">
                <el-input placeholder="请输入关键字查询" suffix-icon="el-icon-search" v-model="keyword" @input="search" />
            </div>
            <el-button type="primary" icon="el-icon-circle-plus-outline" @click="goCreate">添加企业</el-button>
        </div>
        <div class="g-wrap job-bottom" v-loading="loading">
            <div v-for="item in list" class="job-item" :key="item.Id">
                <div class="item-top">
                    <div class="item-left">
                        <div class="mb-10 fs-14 color-base">
                            <el-image :src="item.EnterpriseLogo" class="logo">
                                <div slot="error">
                                    <div class="image-slot">
                                        <span v-if="item.EnterpriseLogo">加载失败</span>
                                        <i v-else class="el-icon-picture-outline"></i>
                                    </div>
                                </div>
                            </el-image>
                        </div>
                        <div class="fs-14">
                            <span class="mb-10 job-name">{{ item.EnterpriseName }}</span>
                        </div>
                    </div>
                    <!-- <div class="item-right">
            <div class="right-btn">
              <span>民营</span>
            </div>
            <div class="right-btn">
              <span>10-50人</span>
            </div>
          </div> -->
                </div>
                <div class="item-bottom">
                    <!-- <el-button type="text" icon="el-icon-s-flag" size="small" class="color-primary" @click="goJobPush(item.Id,item.EnterpriseId)">岗位审核（{{item.JobPushCount}}）</el-button> -->
                    <!-- <el-button type="text" icon="el-icon-s-promotion" size="small" class="color-primary" @click="goJob(item.EnterpriseId)">岗位管理（{{item.JobCount}}）</el-button> -->
                    <el-button type="text" icon="el-icon-search" size="small" class="color-primary" @click="goDetail(item.Id)">查看</el-button>
                    <el-button type="text" icon="el-icon-edit" size="small" class="color-primary" @click="goEdit(item.Id)">编辑</el-button>
                    <el-button type="text" icon="el-icon-delete" size="small" class="color-primary" @click="deleteEnterprise(item.Id)">删除</el-button>
                </div>
            </div>
        </div>
        <div class="loading">
            <div v-if="loading">加载中...</div>
            <div v-else-if="noMore">—— 没有更多了 ——</div>
            <el-button v-else @click="load" type="text">加载更多</el-button>
        </div>

        <el-dialog title="企业推送岗位" :visible.sync="pushDialogVisible" width="800px" :close-on-click-modal="false" :close-on-press-escape="false">
            <el-table :data="recruitJobPushSchoolList" height="400" border style="width: 100%">
                <el-table-column prop="RecruitJobName" label="岗位" />
                <el-table-column prop="EnterpriseName" label="企业" />
                <el-table-column prop="Status" label="状态" width="100">
                    <template slot-scope="scope">
                        <el-tag v-if="pushStatus(scope.row.Status).type" :type="pushStatus(scope.row.Status).type" disable-transitions>{{ pushStatus(scope.row.Status).text }}</el-tag>
                    </template>
                </el-table-column>
                <el-table-column prop="address" label="操作" fixed1="right" width="150">
                    <template slot-scope="scope">
                        <el-button type="text" size="small" icon="el-icon-search" @click="viewJob(scope.row)">查看</el-button>
                        <el-button v-if="pushStatus(scope.row.Status).isCanOperate" type="text" size="small" icon="el-icon-edit" @click="approveJob(scope.row.Id)">通过</el-button>
                    </template>
                </el-table-column>
            </el-table>
        </el-dialog>

        <el-dialog :title="currentRecruitJobName" :visible.sync="jobDialogVisible" width="1000px" :close-on-press-escape="false">
            <job-form v-if="currentRecruitJobId" :id="currentRecruitJobId" :isView="true" />
        </el-dialog>
    </div>
</template>

<script>
    import { mapGetters } from "vuex";
    import {
        RecruitJobPushSchoolStatusEnum,
        StaffTypeEnum
    } from "@/utils/constant";
    import { getPageList, deleteEnterprise } from "@/api/schoolenterprise";
    import { getListBySchool, changeStatus } from "@/api/recruitjobpushschool";
    import JobForm from "@/views/job/components/JobForm.vue";
    export default {
        components: { JobForm },
        data() {
            return {
                list: [],
                keyword: "",
                loading: true,
                pageIndex: 0,
                pageSize: 5,
                totalCount: 0,
                SchoolId: undefined,

                pushDialogVisible: false,
                recruitJobPushSchoolList: [],
                jobDialogVisible: false,
                currentRecruitJobId: "",
                currentRecruitJobName: "",
                currentId: ""
            };
        },
        computed: {
            ...mapGetters(["employee"]),
            noMore() {
                return this.loading == false && this.list.length == this.totalCount;
            },
            pushStatus() {
                return status => {
                    let statusObject = {};
                    switch (status) {
                        case RecruitJobPushSchoolStatusEnum.Running:
                            statusObject = {
                                type: "info",
                                text: "待审批",
                                isCanOperate: true
                            };
                            break;
                        case RecruitJobPushSchoolStatusEnum.Approved:
                            statusObject = {
                                type: "primary",
                                text: "已通过",
                                isCanOperate: false
                            };
                        default:
                            break;
                    }
                    return statusObject;
                };
            },
            runningJobPushCount() {
                return;
            }
        },
        mounted() {
            const { Employer: { Id } = {} } =
            this.employee.find(
                ({ StaffType }) => StaffType === StaffTypeEnum.School
            ) || {};
            if (Id) {
                this.SchoolId = Id;
                this.load();
            }
        },
        methods: {
            load() {
                this.loading = true;
                this.pageIndex++;
                var queryData = {
                    KeyWord: this.keyword,
                    PageSize: this.pageSize,
                    PageIndex: this.pageIndex,
                    SchoolId: this.SchoolId
                };
                getPageList(queryData).then(({ Result: { Data, TotalCount } }) => {
                    this.list = this.list.concat(Data);
                    this.totalCount = TotalCount;
                    this.loading = false;
                });
            },
            search() {
                this.loading = true;
                this.pageIndex = 1;
                var queryData = {
                    KeyWord: this.keyword,
                    PageSize: this.pageSize,
                    PageIndex: this.pageIndex,
                    SchoolId: this.SchoolId
                };
                getPageList(queryData).then(({ Result: { Data, TotalCount } }) => {
                    this.list = Data;
                    this.totalCount = TotalCount;
                    this.loading = false;
                });
            },
            deleteEnterprise(id, index) {
                this.$confirm("删除企业后，将删除企业关联的所有信息（包含员工、合作项目、岗位等），且无法恢复，请谨慎操作！", "温馨提示", {
                        confirmButtonText: "确定",
                        cancelButtonText: "取消",
                        type: "warning",
                        showInput: true,
                        inputPlaceholder: '请输入需要删除的企业名称，确认删除',
                        inputErrorMessage: '企业名称输入有误',
                        beforeClose: (action, instance, done) => {
                            if (action == 'confirm') {
                                if (this.list.find(x => x.Id == id && x.EnterpriseName == instance.inputValue)) {
                                    done();
                                } else {
                                    this.$message.error("企业名称输入有误");
                                }
                            } else {
                                done();
                            }
                        }
                    })
                    .then(() => {
                        deleteEnterprise(id).then(_ => {
                            this.list.splice(index, 1);
                            if (this.totalCount > 0) {
                                this.totalCount--;
                            }
                            this.$message.success("删除成功");
                        });
                    })
                    .catch(_ => {});
            },
            goDetail(id) {
                this.$router.push(`/school/enterpriselist/detail?id=${id}`);
            },
            goEdit(id) {
                this.$router.push(`/school/enterpriselist/edit?id=${id}`);
            },
            goCreate() {
                this.$router.push("/school/enterpriselist/create");
            },
            goJob(enterpriseId) {
                this.$router.push(`/school/job?e=${enterpriseId}`);
                // this.$router.push({name:'ListJob',params:{e:enterpriseId}});
            },
            goJobPush(id, enterpriseId) {
                this.currentId = id;
                this.pushDialogVisible = true;
                getListBySchool(this.SchoolId, enterpriseId).then(({ Result }) => {
                    this.recruitJobPushSchoolList = Result;
                });
            },
            approveJob(id) {
                changeStatus({
                    Id: id,
                    Status: RecruitJobPushSchoolStatusEnum.Approved
                }).then(_ => {
                    this.recruitJobPushSchoolList.forEach(item => {
                        if (item.Id === id) {
                            item.Status = RecruitJobPushSchoolStatusEnum.Approved;
                        }
                    });
                    this.list.forEach(item => {
                        if (item.Id === this.currentId) {
                            item.JobCount++;
                            item.JobPushCount--;
                        }
                    });
                });
            },
            viewJob({ RecruitJobId, RecruitJobName } = {}) {
                //todo:为了强制刷新子组件
                this.currentRecruitJobId = "";
                setTimeout(() => {
                    this.currentRecruitJobId = RecruitJobId;
                }, 0);
                this.currentRecruitJobName = RecruitJobName;
                this.jobDialogVisible = true;
            },
            handleClose() {}
        }
    };
</script>
<style lang="scss" scoped>
    .job-container {
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
        background: #f5f5f5;
    }

    .job-top {
        justify-content: space-between;
        margin-top: 20px;
    }

    .g-wrap {
        background: #fff;
    }

    .g-wrap.job-top {
        padding: 24px 24px 16px;
        border-bottom: 1px solid #eeeeee;
        // flex-direction: column;
    }

    .g-wrap.job-bottom {
        padding: 16px 24px;
        margin-bottom: 20px;
    }

    .btn-search {
        margin-left: 10px;
    }

    .job-bottom {
        display: flex;
        flex-direction: column;
        // margin-top: 20px;
        border-radius: 0px 0px 8px 8px;
        min-height: 30vh;
    }

    .job-item {
        display: flex;
        justify-content: space-between;
        flex-direction: column;
        width: 100%;
        margin-bottom: 16px;
        border: 1px solid #e2e4e8;
        border-radius: 6px;

        &:hover {
            box-shadow: 0px 2px 6px 0px #e7e7e7;
        }

        .item-top {
            padding: 10px;
            display: flex;
            justify-content: space-between;

            .item-left {
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                align-items: flex-start;

                .job-name {
                    font-size: 18px;
                    font-weight: 600;
                    color: #333333;
                }
            }

            .item-right {
                display: flex;
                align-items: center;

                .right-btn {
                    cursor: pointer;
                    margin-left: 20px;
                    display: flex;
                    flex-direction: column;
                }
            }
        }

        .item-bottom {
            display: flex;
            border-top: 1px solid #e7e8ea;
            background: #eeeeee;
            height: 32px;
            align-items: center;
            justify-content: flex-end;
            padding: 8px 10px;
            border-radius: 0 0 6px 6px;
        }

        .el-button {
            padding: 0px;
        }
    }

    .loading {
        text-align: center;
        height: 30px;
    }

    .image-slot {
        height: 60px;
        width: 60px;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: #f5f7fa;
    }

    .logo {
        width: 60px;
        height: 60px;
    }
</style>