import request from '@/utils/request'
const controller = '/RecruitJobPushSchool'

const edit = (jobId, data) =>
  request({
    url: `${controller}/Update?jobId=${jobId}`,
    method: "post",
    data
  });
const getListBySchool = (schoolId, enterpriseId) =>
  request({
    url: `${controller}/GetListBySchool`,
    method: "get",
    params: {
      schoolId,
      enterpriseId
    }
  });
const changeStatus = data =>
  request({
    url: `${controller}/ChangeStatus`,
    method: 'post',
    data
  });
export {
  edit,
  getListBySchool,
  changeStatus
}